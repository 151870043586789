footer {
    background: #264DB1;
    padding-top: 64px;
    padding-bottom: 51px;
}

p {
    margin-bottom: 0px;
}

footer .container {
    display: flex;
    padding: 0;
    justify-content: space-between;
}

footer .bottom-footer {
    margin-top: 72px;
}

footer .info-company-footer {
    padding: 21px 17px;
    width: 337px;
    background: #23469F;
    border-radius: 6px;
}

footer .info-company-footer p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #DBE0EF;
    margin-top: 23px;
    margin-bottom: 20px;
}

footer .socials .social-link{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: #4465BA;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
    margin: 0 5px;
  }

  footer .socials .social-link .social-icon {
    color: #fff;
    font-size: 14px;
  }

  .links-pages-footer {
    padding-left: 57px;
}

footer .header-footer-boxes {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #E7EBF6;
    margin-bottom: 15px;
}

.links-pages-footer ul li:not(:last-child) {
    margin-bottom: 8px;
}
.links-pages-footer ul li {
    list-style: none;
}

.links-pages-footer ul li a {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #94A3D2;
}

.destination-footer {
    padding-right: 57px;
}

.links-pages-footer, .destination-footer {
    width: 23%;
}

.destination-footer > div {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #94A3D2;
    margin-top: 8px;
    display: flex;
    align-items: center;
}


.destination-footer > div div {
    margin-right: 8px;
    min-width: 32px;
    height: 32px;
    background: #4465BA;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.subscribe-box-footer {
    width: 22%;
}

.subscribe-box-footer p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #94A3D2;
}

.subscribe-box-footer .subscibe-input-footer {
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.14);
    background: rgba(255, 255, 255, 0.06);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    padding-left: 15px;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 32px;
}

.subscribe-box-footer .subscibe-input-footer input {
    height: 100%;
    background: rgba(255 255 255 / 0%);
    border: none;
    outline: none;
    padding-left: 11px;
    color: #94A3D2;
}

.subscribe-box-footer .subscibe-input-footer button {
    height: 100%;
    border: none;
    outline: none;
    position: absolute;
    right: 0;
    width: 40px;
    background: rgba(255, 255, 255, 0.06);
}

.subscribe-box-footer .subscibe-input-footer button img {
    filter: brightness(6);
}

footer .bottom-footer {
    margin-top: 72px;
}

footer .bottom-footer .container {
    padding-top: 40px;
    border-top: 1px solid #486AC2;
    display: flex;
    justify-content: space-between;
}

footer .bottom-footer .left-bottom-foot p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    margin-left: 16px;
}
#useremail {
    color: #94A3D2; 
}
body.dark-theme footer {
    background: #171C29;
}

body.dark-theme .info-company-footer {
    background: #252C3F;
}

body.dark-theme .destination-footer > div div, body.dark-theme .info-company-footer .sotset-footer a {
    background: #343E58;
}
body.dark-theme .subscribe-box-footer p, body.dark-theme .destination-footer > div p, body.dark-theme  .destination-footer span, body.dark-theme .links-pages-footer ul li a {
    color: #A2A9BC !important;
}

.subscribe-box-footer .subscibe-input-footer button img {
    filter: brightness(6);
}