.smart-container {
    text-align: center;
}
.smart-container .container {
    width: 1219px;
    margin: 50px auto;
}
.card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Set to 4 cards per row */
    gap: 20px;
    margin-top: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-decoration: none;
    color: #333;
    transition: transform 0.2s ease;
}

.card:hover {
    transform: translateY(-5px);
}
.card:hover .card-icon {
    color: #2fd10a;
    transition: 0.2s ease-in;
}
.card-icon {
    font-size: 65px;
    margin-bottom: 10px;
    color: #264DB1;
}

.card-title {
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-family: 'Roboto', sans-serif;
}

.password-container {
    width: 1219px;
    margin: 120px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.password-container h6 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: normal;
    color: #333;
}
.password-container input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
}
.password-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #264DB1;
    color: #fff;
    cursor: pointer;
    margin: 0 15px;
}
@media (max-width: 768px) {
    .card-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row on smaller screens */
    }
}

@media (max-width: 480px) {
    .card-grid {
        grid-template-columns: 1fr; /* 1 card per row on very small screens */
    }
}
