body {
  background-color: #fff;
}

.container {
  padding: 0;
}
ul {
  padding-left: 0;
}
p {
    margin-bottom: unset;
}

.home-container {
    width: 100%;
    display: flex;
}
.home-container .container {
  padding: 0;
}
.news-section {
    width: 100%;
    display: flex;
    gap: 20px;
}

.news-large {
    width: 76%;
    height: 480px;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.news-large .news-title {
    font-size: 32px;
}

.news-small-container {
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.news-small {
    height: 230px;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.news-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.news-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px;
    /*background: rgba(0, 0, 0, 0.6);*/
    color: #fff;
    transition: transform 0.3s ease;
}

.news-title {
    margin: 0;
    font-size: 18px;
}

.news-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transform: translateY(0px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.news-large:hover .news-details, .news-small:hover .news-details {
    opacity: 1;
    transform: translateY(0);
}

.news-large:hover .news-info, .news-small:hover .news-info {
    transform: translateY(-20px);
}

.news-large:hover .news-image, .news-small:hover .news-image {
    transform: scale(1.05);
}
.inter-section {
    /*height: 100vh;*/
    padding-top: 50px;
    /*display: none;*/
}
body.dark-theme .inter-section {
    background-color: #31384E;
    margin-bottom: 50px;
}
body.dark-theme .inter-section .container {
  height: 230px;
}
.inter-section .container {
    /*width: 1219px;*/
    margin: 1% auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Add some spacing between cards */
    margin-top: 30px;
}

.interactiv-card {
    flex: 1 1 30%; /* Each card takes up 30% of the row */
    width: 33%; /* Minimum width of the card */
    height: 120px; /* You can adjust this as needed */
    background-color: #fff;
    border: 1px solid #DBE0EF;
    border-radius: 15px;
    padding: 24px;
    text-decoration: none;
    position: relative;
    font-family: "Roboto", sans-serif;
}
body.dark-theme .interactiv-card {
    background: #31384E;
    border: 1px solid #535A70;
}
body.dark-theme .interactiv-card::before {
    background-color: #fff;
}
body.dark-theme .interactiv-card p {
    color: rgb(255 255 255 / 76%);
}
.interactiv-card p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #39424F;
}
.interactiv-card .counts {
    font-size: 12px;
    line-height: 24px;
    padding: 0px 10px;
    position: absolute;
    right: 15px;
    bottom: 10px;
}

.interactiv-card .counts, .interactiv-card > span {
    padding: 6px 10px;
    background: rgba(35, 200, 64, 0.1);
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    /*font-family: 'Inter';*/
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 10px;
    color: #23C840;
}
.interactiv-card .counts img, .interactiv-card > span img {
    margin-right: 11px;
}
.interactiv-card::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 77px;
    left: 0;
    top: calc(50% - 77px / 2);
    background: #264DB1;
    border-radius: 30px;
}
.interactiv-card:hover {
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    transition: 0.5s ease-in;
}
/* Responsive design - making cards stack in smaller screens */
@media (max-width: 768px) {
    .interactiv-card {
        flex: 1 1 45%; /* Cards take up 45% of the row on smaller screens */
    }
}

@media (max-width: 480px) {
    .interactiv-card {
        flex: 1 1 100%; /* Cards take up full width on very small screens */
    }
}





.news-section {
    width: 100%;
    margin-bottom: 5rem;
}
.news-section .container{
    margin-top: 50px;
    padding: 0;
}

.news-section .container .news-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.news-section .container .news-section-header .view-all {
    display: flex;
    align-items: flex-end;
    gap: 20;
    text-decoration: none;
    color: #264DB1;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}
body.dark-theme .news-section .container .news-section-header .view-all {
    color: #939FB8 !important;
}
.news-section .container .news-section-header .view-all img {
    padding-left: 10px;
}
.view-all img {
    filter: brightness(2);
    margin-left: 17px;
}
.news-section .container .news-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.news-section .container .news-container .news-link {
    padding: 13px 14px;
    border: 1px solid #fff;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12);
    text-decoration: none !important;
    color: #111827;
}
.news-link {
    width: 32%;
    border-radius: 16px;
}
.news-link {
    padding: 13px 14px;
    border: 1px solid #fff;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .news-section .container .news-container .news-link {
    border: 1px solid #252C3F;
}
body.dark-theme .info-news h3 {
    color: #939FB8 !important;
}
.news-section .container .news-container .news-link:hover {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    cursor: pointer;
    transition: 1s;
}
body.dark-theme .news-section .container .news-container .news-link:hover:hover {
    border: 1px solid #31384E;
    background: #31384E;
}
.news-section .container .news-container .news-link a {
    text-decoration: none;
}

.news-link .img-news-link {
    margin-bottom: 12px;
    border-radius: 12px;
    width: 100%;
    height: 230px;
    object-fit: cover;
}

.data-time {
    display: flex;
    justify-content: space-between;
}

.data-time span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #4B5563;
    display: flex;
}
body.dark-theme .news-section .container .news-container .news-link .info-news p, body.dark-theme .data-time span {
    color: #939FB8 !important;
}
.data-time span img {
    margin-right: 7px;
    max-width: 15px;
}

.news-link .data-time .data {
    margin-left: 19px;
}

.data-time span img:not(:first-child) {
    margin-left: 16px;
}

.news-section .container .news-container .news-link .info-news p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 21px;
    color: #111827;
    margin-top: 12px;
}

.carousel-container {
    width: 100%;
    /*height: 400px;  Задайте нужную высоту */
   /* margin: 0 auto;*/
    padding: 20px 0;
    overflow: hidden; /* Ограничивает размер изображений в контейнере */
}
.carousel-container .container {
  padding: 0;
}
.carousel-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Обрезка изображения по контейнеру, сохраняя пропорции */
    border-radius: 10px;
}

.info-section {
    width: 100%;
    height: 100%;
    margin-top: 5rem;
}

.info-section .container {
   margin-top: 30px;
   padding: 0;
}
.info-section .container .jv_services_btns {
    max-width: 100%;
    width: 100%;
    display: flex;
    gap: 30px;
}
.info-section .container .jv_item {
    background: #FFFFFF;
    border: 1px solid #DBE0EF;
    border-radius: 15px;
    padding: 14px 32px;
    color: #39424F;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    width: 33%;
    text-decoration: none;
}

.info-section .container .jv_item:hover {
    background: #264DB1;
    color: white;
}
.info-section .container .jv_item:hover img {
    filter: brightness(100);
}
.info-section .container .jv_item span {
    background: rgba(235, 238, 248, 0.3);
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 15px;
}

body.dark-theme .jv_services_btns .jv_item {
    color: #939FB8;
    background-color: #31384E;
    border-color: transparent;
    box-shadow: 0px 21px 45px -10px rgba(0, 0, 0, 0.1);
}
body.dark-theme .jv_services_btns .jv_item span {
    background: rgba(255, 255, 255, 0.3);
    filter: brightness(100);
}
body.dark-theme .jv_services_btns .jv_item:hover {
    color: #939FB8;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #31384E;
}
.content-section {
    width: 100%;
    height: 100%;
    margin-top: 10rem;
}

.content-section .container {
    padding: 0;
}
body.dark-theme .content-section .container  .content-suggestion  {
    background-color: #23335E;
}

body.dark-theme .link-diagramm-banner span {
    color: #939FB8 !important;
}
.content-section .container  .content-suggestion {
    background: #264DB1;
    border-radius: 20px;
    background-image: url(../images/Group34252.png);
    padding: 31px 56px 32px 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    text-decoration: none;
}

.content-section .container  .content-suggestion p {
    max-width: 100%;
}

.content-section .container .content-suggestion p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    max-width: 794px;
}

.content-suggestion a, .content-suggestion .btnl {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    text-decoration: none;
    padding: 8px 20px;
    border: 1px solid #FFFFFF;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.content-suggestion .btnl img, .content-suggestion a img {
    filter: brightness(6);
    margin-left: 11px;
}

.statisttic-section {
    width: 100%;
    margin-bottom: 50px;
    margin-top: 7rem;
}
.statisttic-section .container {
    display: flex;
    gap: 2rem;
   padding: 0;
  }
  
  .main-panel {
    padding: 21px 7px 25px 7px;
    border: 1px solid #DBE0EF;
    border-radius: 52px;
    width: 66%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lokOne {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80%;
  }
  .lokTwo {
    border-top: 1px solid #ccc;
    width: 95%;
    height: 20%;
  }
  .left-panel {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
  }
  .right-panel {
    padding: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .left-panel h2, .right-panel h2 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  
  .stat-circle {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  

  
  .submit-btn {
    padding: 10px 24px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    background: #264DB1;
    border-radius: 6px;
    border: none;
    height: fit-content;
    cursor: pointer;
  }
  
  .link-all {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #264DB1;
  }
  .link-all img {
    filter: brightness(2);
    margin-left: 17px;
  }
  .sel {
    width: 100%;
  }
  .sel label {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #111827;
  }
  .service-select {
    width: 100%;
    height: 45px;
    padding: 0.4rem;
    border-radius: 5px;
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    outline: none;
  }

  body.dark-theme .service-select {
    background: #31384E !important;
    color: #939FB8 !important;
    border: 1px solid #939FB8;
  }
  body.dark-theme .radio-group label {
    color: #939FB8;
  }
  body.dark-theme label {
    color: #939FB8;
  }
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  .radio-group input {
    margin-right: 10px;
  }
  .radio-group label {
    display: flex;
    align-items: center;
    gap: 10;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
  
  .link-panel {
    border: 1px solid #DBE0EF;
    border-radius: 32px;
    width: 32%;
    padding: 20px;
  }
  
  .carousel {
    position: relative;
    border-radius: 22px;
    height: 343px;
    /*padding: 16px 14px;*/
  }
  
  .carousel-image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  .carousel-caption2 {
    background: rgba(19, 42, 99, 0.5);
    backdrop-filter: blur(4px);
    border-radius: 14px;
    text-align: center;
    position: absolute;
    left: 14px;
    right: 14px;
    bottom: 16px;
    padding: 7px 10px;
  }
  .carousel-caption2  p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #FFFFFF;
  }
  .links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .link-btn {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #EEF2FE;
    border-radius: 20px;
    text-decoration: none;
    margin-top: 16px;
  }
  .link-btn span {
    display: flex;
    align-items: center;
    font-family: 'Roboto-bold', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #264DB1;
  }
 body.dark-theme .link-btn {
    background-color: #31384E;
}
body.dark-theme .link-btn span {
    color: #939FB8 !important;
}

body.dark-theme .link-all {
    color: #939FB8 !important;
}
  .link-btn span div {
    width: 48px;
    height: 48px;
    background-color: #D4DCF6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }

.map-section {
    width: 100%;
    display: none;
}

.map-section .container {
    width: 1219px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 42px;
}

.map-section .container .map-svg {
    padding: 103px 48px;
    width: 66%;
    border: 1px solid #DBE0EF;
    border-radius: 52px;
    display: block;
}

.map-section .container .map-info {
    padding: 24px 35px;
    width: 32%;
    background: #F8F9FF;
    border-radius: 20px;
}


.useful-articles {
    overflow: hidden;
}
.useful-articles {
    margin-top: 56px;
    margin-bottom: 120px;
}

.useful-articles .container {
    position: relative;
    width: 1219px;
    margin: 0 auto;
}

.header-news {
    font-family: 'Inter-bolder';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #111827;
}

.slider-useful-content .owl-carousel {
    position: unset !important;
}
.no-js .owl-carousel, .owl-carousel.owl-loaded {
    display: block;
}

#slider-useful-article .owl-stage-outer {
    padding: 15px 9px;
    margin: -15px -9px;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.useful-articles .box-useful-content {
    padding: 34px 33px;
    border: 1px solid #DBE0EF;
    border-radius: 32px;
    text-align: center;
    display: block;
    text-decoration: none;
}

.useful-articles .box-useful-content img {
    display: inline !important;
    width: 74px !important;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


.slide-container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .image-content {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .card-image {
    width: 100%;
    overflow: hidden;
  }
  
  .card-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .name {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
  }
  
  .description {
    font-size: 14px;
    color: #666;
  }
  
  .button {
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background: #0056b3;
  }

  #scrollToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #264DB1;
    color: white;
    border: none;
    padding: 12px 15px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    z-index: 999;
}

#scrollToTop:hover {
    background-color: #264DB1;
}


/* Общие стили для карусели */

  

  body.dark-theme .carousel-container .card {
    border: 1px solid #535A70;
    background: transparent;
    box-shadow: none;
  }
  .slick-prev {
    display: none;
  }
  /* Картинка и текст внутри карточек */
  .carousel-container .card {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Выравнивание по вертикали */
    align-items: center; /* Выравнивание по горизонтали */
    padding: 34px 33px;
    height: 223px;
    border: 1px solid #DBE0EF;
    border-radius: 22px;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
    text-decoration: none; /* Чтобы ссылка не имела подчеркивания */
  }
  
  .carousel-container .card img {
    max-width: 100%;
    height: auto;
    display: block; /* Изображение будет блочным элементом */
    margin: 0 auto 10px; /* Центрируем изображение и добавляем отступ снизу */
  }
  body.dark-theme .carousel-container .card p {
    color: #939FB8 !important;
  }
  .carousel-container .card p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #39424F;
    margin-top: 16px;
  }
  
  .carousel-container .btnd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }
  .carousel-container .btnd .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .carousel-container .btnd button {
    width: 38px;
    height: 38px;
    background: rgba(53, 88, 181, 0.1);
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .carousel-container .btnd button .icon {
    font-size: 18px;
    color: #264DB1;
  }
  /* Стили для Slider */
  .slick-slide {
    padding: 10px;
  }
  
.gallery-container {
  margin-bottom: 150px;
}
.gallery-container .container {
 padding: 0;
}
/* Стили вкладок */
.tabs {
  display: flex;
  /*border-bottom: 2px solid #e2e7f7;*/
  margin-bottom: 20px;
  margin-top: 50px;
}

/*.tab-btn {
   width: 150px;
  padding: 0px 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: left;
  font-size: 20px;
  margin-bottom: 10px;
}*/

.tab-btn.active {
  color: #264DB1;
  
}
.tabs ul {
  width: 100%;
  display: flex;
  border-bottom: 3px solid #e2e7f7;
}
.tabs ul li {
  padding-top: 12px;
    padding-bottom: 12px;
    list-style: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #4B5563;
    cursor: pointer;
    margin-right: 80px;
    border-bottom: 1px solid #fff;
}
.tab-btn:hover {
  color: #264DB1;
}

/* Стили содержимого вкладок */
.tab-content {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;
}

.tab-content.slide-in {
  transform: translateY(20px);
  opacity: 0;
}

/* Стили для галереи */
.photo-grid, .video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.photo-grid img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 270px;
}

.video-grid video {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.tabs ul li :hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

.tabs ul li :hover:before {
  content: "";
  position: absolute;
  width: 100%;
  background-color: #4465BA;
  height: 3px;
  bottom: -2px;
  left: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease-in-out;
  border-radius: 15px; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 18px;
  z-index: 10;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
}


.carousel-caption2 {
  position: absolute;
  /* right: 15%; */
  /* bottom: 1.25rem; */
  /* left: 15%; */
  padding-top: 1.25rem;
  /* padding-bottom: 1.25rem; */
  color: #fff;
  text-align: center;
}