.emblem-container .container{
    width: 100%;
}

body.dark-theme .emblem-title {
    opacity: 1;
    transition: 1s;
    background-color: #252C3F;
}

.emblem-title {
    background: #264DB1;
    background-image: url(../images/Group34252.png);
    padding-top: 114px;
    padding-bottom: 32px;
    background-position: center;
}

.emblem-title .container {
padding: 0;
}
.emblem-title .container > div {
    display: flex;
    justify-content: space-between;
}
.emblem-title h1 {
    /*font-family: 'Inter';*/
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    margin-bottom: 28px;
}

 .left-side-topmenu ul {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.emblem-title .left-side-topmenu ul li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 19px;
}

.emblem-title .left-side-topmenu ul li a {
    text-decoration: none;
   /* font-family: 'Inter';*/
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    margin-left: 13px;
    max-width: 271px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.emblem-title .right-side-topmenu, .emblem-title .left-side-topmenu ul {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.emblem-title .right-side-topmenu > a {
  /* font-family: 'Inter';*/
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    text-decoration: none;
    margin-right: 27px;
    display: flex;
    align-items: center;
}

.emblem-title .right-side-topmenu a > img {
    margin-right: 12px;
}

.emb-container {
    max-width: 802px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
}

.emb-one {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.emb-two {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem;
}

.emb-three {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem;
}
.emb-one h1 {
    font-size: 26px;
}

.emb-two h1 {
    font-size: 26px;
}
.emb-one img {
    max-width: 100%;
    object-fit: cover;
}

.emb-one p {
    /*font-family: 'Inter';*/
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-align: justify;
    color: #111827;
}

.emb-two p {
       /*font-family: 'Inter';*/
       font-style: normal;
       font-weight: 500;
       line-height: 32px;
       text-align: justify;
       color: #111827; 
}

.emb-three p {
           /*font-family: 'Inter';*/
           font-style: normal;
           font-weight: 500;
           line-height: 32px;
           text-align: center;
           color: #111827; 
}

body.dark-theme .emb-one p {
    color: rgb(255 255 255 / 76%);
}
body.dark-theme .emb-two p {
    color: rgb(255 255 255 / 76%);
}

body.dark-theme .emb-three p {
    color: rgb(255 255 255 / 76%);
}


/*=============NEWS DETAL SECTION START =============*/
.news-detal-container {
        max-width: 802px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 64px;
}

.news-detal-container .tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
}

.news-detal-container .tools>div:first-child{
    display: flex;
    align-items: center;
    gap: 20;
}

.news-detal-container .tools>div:first-child p {
    display: flex;
    align-items: center;
    gap: 20;
    margin: 0 10px;

}
.news-detal-container .tools>div:first-child p:first-child {
    margin: 0;
}
.news-detal-container .tools>div p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #4B5563;
}
.news-detal-container .tools>div p img {
    margin-right: 10px;
}
.newsall {
    display: flex;
    align-items: flex-start;
}
.newslist-link {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #264DB1;
}
.btn-share {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    color: #ffff;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    
}
.all {
    text-decoration: none;
}
.news-text {
    margin-bottom: 2rem;
}
.news-text p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    text-align: justify;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #111827;
}
body.dark-theme .news-text p {
    color: #939FB8 !important;
}
body.dark-theme .newslist-link {
    color: #939FB8 !important; 
}
body.dark-theme .news-detal-container .tools>div p {
    color: #939FB8 !important;
}
body.dark-theme .news-detal-container .tools>div p img {
    filter: brightness(6);
}

.dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 4px;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown a {
    color: #333;
    padding: 8px 12px;
    text-decoration: none;
  }
  
  .dropdown a:hover {
    background-color: #f1f1f1;
  }
/*=============NEWS DETAL SECTION END =============*/

/*=============Regions start =============*/
.regions-container {
    width: 100%;
}
.regions-container .container {
    width: 1219px;
    margin: 0 auto;
}

.regions-container .container .position-box {
    background: #FFFFFF;
    border: 1px solid #DBE0EF;
    border-radius: 32px;
    padding: 32px;
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 16px;
}

.regions-container .container .position-box .position-holder {
    min-width: 300px;
    max-width: 260px;
    height: 224px;
    border: 1px solid #DBDBDB;
    border-radius: 12px;
}

.regions-container .container .position-box .position-holder img {
    object-position: top;
}

.regions-container .container .position-box .position-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}
.about-position {
 font-family: 'Roboto', sans-serif;
}

.about-position>div:last-child {
    margin-top: 15px;
    color: #111827;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.about-position>div:last-child .map {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 550;
    color: #264DB1;
}
/*=============Regions end =============*/


/*==========Documents start =============*/
.document-container{
    width: 100%;
    margin-top: 64px;
}

.document-container .conatiner {
    width: 1219px;
    margin: 0 auto;

}
.document-container .conatiner .document-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.document-container .conatiner .document-cards .document-card{
    padding: 20px;
    background: #e2e0fb;
    border-radius: 32px;
    width: calc(100% / 3 - 20px);
    position: relative;
    margin-bottom: 30px;
    transition: 0.4s ease-out;
    overflow: hidden;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.document-container .conatiner .document-cards .document-card .top-img {
    width: 85px;
    height: 85px;
    background: #ECF1FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.document-container .conatiner .document-cards .document-card p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 540;
    font-size: 20px;
    line-height: 120%;
    color: #252f44;
    margin-top: 24px;
    margin-bottom: 60px;
    text-align: center;
    /* width: 48%; */
}

.document-container .conatiner .document-cards .document-card .data-bottom-doc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 24px;
    left: 28px;
    right: 28px;
}

