.contact-ministry {
    padding-top: 120px;
    padding-bottom: 64px;
}

.contact-ministry .container {
    display: flex;
    justify-content: space-between;
    width: 1240px;
    margin: 0 auto;
}

.contact-ministry .left-side-contact {
    width: 39%;
}

.contact-ministry .header-contact-page {
    margin-bottom: 64px;
}

.contact-ministry .left-side-contact .info-contact:not(:last-child) {
    margin-bottom: 32px;
}

.contact-ministry .left-side-contact .info-contact {
    display: flex;
}

.header-contact-page {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    font-feature-settings: 'salt' on;
    color: #111827;
}

.contact-ministry .left-side-contact .info-contact:not(:last-child) {
    margin-bottom: 32px;
}
.contact-ministry .left-side-contact .info-contact {
    display: flex;
}

.contact-ministry .left-side-contact .info-contact > img {
    margin-right: 14px;
    align-self: baseline;
}

.contact-ministry .left-side-contact .info-contact div h6 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #111827;
    margin-bottom: 10px;
}

.contact-ministry .left-side-contact .info-contact div p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #6B7280;
    margin-bottom: 10px;
}

.link_all {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #264DB1;
}

.contact-ministry .left-side-contact .info-contact .link-contact:not(:last-child) {
    margin-bottom: 10px;
}
.contact-ministry .left-side-contact .info-contact .link-contact {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #264DB1;
    text-decoration: none;
    display: block;
    display: flex;
    align-items: center;
}

.left-side-contact .info-contact .link-contact img {
    margin-right: 10px;
}

.contact-ministry .left-side-contact .info-contact span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #6B7280;
    display: block;
}

.contact-ministry .right-side-contact {
    width: 56%;
}

.contact-ministry .right-side-contact .send-link a.current, .contact-ministry .right-side-contact .send-link a:hover {
    color: #264DB1;
    border-bottom: 1px solid #264DB1;
    cursor: pointer;
}
.contact-ministry .right-side-contact .send-link a:not(:last-child) {
    margin-right: 80px;
}
.contact-ministry .right-side-contact .send-link a {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #fff;
    color: #4B5563;
}
.contact-ministry .right-side-contact form {
    margin-top: 32px;
}
.contact-ministry .right-side-contact .form-fill-contact.current {
    display: block;
}

.right-side-contact form div label textarea, .contact-ministry .right-side-contact form input {
    padding: 10px 12px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #9CA3AF;
    margin-top: 4px;
}
.contact-ministry .right-side-contact form input {
    width: 100%;
    display: block;
}
.contact-ministry .right-side-contact form div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.contact-ministry .right-side-contact form label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #111827;
}

.contact-ministry .right-side-contact form div label {
    width: 48%;
    margin-bottom: 32px;
    position: relative;
    font-weight: 550;
    color: #111827;
}
.contact-ministry .right-side-contact form label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #111827;
}
label {
    display: inline-block;
}
.contact-ministry .right-side-contact form input {
    width: 100%;
    display: block;
}
.right-side-contact form div label textarea, .contact-ministry .right-side-contact form input {
    padding: 10px 12px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #9CA3AF;
    margin-top: 4px;
}

.current-statistic {
    border: 1px solid #D1D5DB;
    padding: 11px 12px;
    position: relative;
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 4px;
}

.current-statistic span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #9CA3AF;
}

.dropdown-statistic {
    transition: 0s;
    z-index: 999;
}
.dropdown-statistic {
    padding: 0;
    border: none;
    width: 100%;
    outline: none;
}

.dropdown-statistic ul {
    width: 100%;
    z-index: 999;
}

.type-subject {
    margin-bottom: 10px;
}

.dropdown-statistic ul li {
    padding: 8px 15px;
    margin-bottom: 0 !important;
}
.dropdown-statistic ul li {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #9CA3AF;
    list-style: none;
    -moz-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    -webkit-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 16px;
}

.contact-ministry .right-side-contact form .textarea-contact {
    width: 100%;
}
.contact-ministry .right-side-contact form textarea {
    width: 100%;
}

.contact-ministry .right-side-contact form label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #111827;
}

.contact-ministry .right-side-contact form .file-contact {
    padding: 8px 20px;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    width: auto;
    display: flex;
    align-items: center;
    font-size: 16px;
}
.contact-ministry .right-side-contact form .input-file-contact {
    display: none;
}
.contact-ministry .right-side-contact form .file-contact img {
    margin-right: 9px;
}

.contact-ministry .right-side-contact form div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact-ministry .right-side-contact .bottom-contact-page {
    border-top: 1px solid #F2F2F2;
    padding-top: 24px;
}
.contact-ministry .right-side-contact form .input-form-checkbox {
    width: 60%;
    display: inline-flex;
}
.contact-ministry .right-side-contact form input[type=checkbox] {
    appearance: none;
    display: inline;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
}
.right-side-contact form .input-form-checkbox span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #9CA3AF;
}

.right-side-contact form button {
    padding: 10px 24px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    background: #264DB1;
    border-radius: 6px;
    border: none;
    height: fit-content;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.right-side-contact form .input-form-checkbox span a {
    text-decoration: none;
    color: #2053d5;
}

.contact-ministry .right-side-contact form input[type=checkbox]:checked {
    background-image: url(../images/checkbox.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: #264DB1;
}

.contact-ministry .right-side-contact form input[type=checkbox] {
    appearance: none;
    display: inline;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
}