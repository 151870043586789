.news-form .form-control, .form-select {
    border-radius: 8px;
    font-size: 1rem;
}

.btn-success, .btn-primary {
    border-radius: 8px;
    font-size: 1.1rem;
}

.news-list .table-hover tbody tr:hover {
    background-color: #f9f9f9;
}

.photo-preview img {
    max-width: 150px;
    height: auto;
    border-radius: 8px;
}

.table>:not(caption)>*>* {
    padding: .2rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}