/* Таблица */
.photo-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}

.photo-table th,
.photo-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.photo-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

/* Изображения */
.photo {
    width: 100px;
    height: auto;
    border-radius: 4px;
}

/* Стиль для контейнера пагинации */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Стиль для каждой страницы пагинации */
.pagination a {
    display: inline-block;
    padding: 10px 15px;
    margin: 0 5px;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

/* Стиль для активной страницы */
.pagination .active a {
    color: #007bff;
    background-color: none;
}

/* Стиль для ссылки при наведении */
.pagination a:hover {
    color: #007bff;
    background-color: none;
}

/* Стиль для кнопок "Назад" и "Далее" */
.pagination .previous, .pagination .next {
    font-weight: bold;
    border: none;
}

/* Стиль для больших отступов (отображение многоточия) */
.pagination .break {
    font-size: 18px;
    font-weight: bold;
}
