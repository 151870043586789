*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
img, svg {
    vertical-align: middle;
}

ul {
  padding-left: 0;
}

:root {
    --Bgcolor: #fff;
    --Blackcolor: #264DB1;
    --Whitecolor: #fff;
    --Graycolor: #ccc;
    --Darkgraycolor: #666;
    --Lightgraycolor: #f5f5f5;
    --Redcolor: #f00;
    --Greencolor: #0f0;
    --Bluecolor: #00f;
    --Yellowcolor: rgb(255, 187, 0);
    --Purplecolor: #800;
    --Orangecolor: #ff8000;
    --Pinkcolor: #ff0;
    --Font-family: "Roboto", sans-serif;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.navbar {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.navbar .center-navbar {
    width: 100%;
    padding-top: 52px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navbar .center-navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.navbar .center-navbar .container .right-center-nav {
    display: flex;
    align-items: center;
}

.navbar .center-navbar .container .right-center-nav .btn-vertual {
    display: flex;
    padding: 8px 15px 8px 15px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    margin-right: 32px;
    text-decoration: none;
}
.navbar .center-navbar .container .right-center-nav .btn-vertual p {
    
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #374151;
    margin-right: 11px; 
}
.navbar .center-navbar .container .right-center-nav .btn-secrt {
    text-decoration: none;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
}
.navbar .center-navbar .container .right-center-nav .btn-secrt span {
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #374151;
}
.navbar .center-navbar .container .right-center-nav .btn-secrt span img {
    filter: brightness(0.5);
    margin-left: 2px;
}
.navbar .center-navbar .container .right-center-nav .btn-secrt strong {
    
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #111827; 
}

.navbar-btns {
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}

.navbar-btns.hidden {
  opacity: 0;
  pointer-events: none; /* Отключаем кликабельность элементов */
}

.search-container {
  display: flex;
  align-items: center;
  position: absolute;
  /*top: 50%;*/
  right: 10%;
 /* transform: translateY(-50%);*/
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.search-container.open {
  opacity: 1;
  visibility: visible;
}

.search-input {
  width: 200px; /* Ширина поля поиска */
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: width 0.3s ease;
}

.search-container.open .search-input {
  width: 450px; /* Ширина увеличивается, когда поле открыто */
  height: 45px;
  padding-left: 30px; /* Отступ для иконки */
  outline: none;

}

.search-icon-inside {
  position: absolute;
  right: 10px;
  font-size: 62px;
  color: #fff;
  background: #264DB1;
  border-radius: 4px;
  width: 33px;
  height: 33px;
  padding: 10px 10px;
  align-items: center;
  justify-content: center;
}

.btn-search {
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

/* Для кнопок виртуальной приемной и телефона */
.btn-vertual,
.btn-secrt {
  transition: opacity 0.3s ease;
}

.btn-vertual.hidden,
.btn-secrt.hidden {
  opacity: 0;
}

/* Hide the links when search is open */
.navbar-btns.hidden {
    display: none;
  }
  
  /* Slide-in animation */
  .navbar-btns.slide-in {
    display: flex;
    animation: slideInFromLeft 0.5s ease forwards;
  }

  body.dark-theme .navbar .center-navbar .container .right-center-nav .btn-vertual {
    background-color: #41485C;
    border: none;
  }

  body.dark-theme .navbar .center-navbar .container .right-center-nav .btn-vertual p {
    color: rgb(255 255 255 / 76%);
  }
  body.dark-theme .navbar .center-navbar .container .right-center-nav .btn-secrt span {
    color: rgb(255 255 255 / 76%);
  }
  body.dark-theme .navbar .center-navbar .container .right-center-nav .btn-secrt strong {
    color: rgb(255 255 255 / 76%);
  }
  body.dark-theme .navbar .center-navbar .container .right-center-nav .btn-vertual img{
    filter: brightness(6);
  }
  body.dark-theme .search-icon {
    color: rgb(255 255 255 / 76%);
  }
  body.dark-theme .search-container.open .search-input {
    background: transparent;
    color: rgb(255 255 255 / 76%);
  }

  @keyframes slideInFromLeft {
    from {
      opacity: 0;
      transform: translateX(-100%); /* Slide in from the left */
    }
    to {
      opacity: 1;
      transform: translateX(0); /* End position */
    }
  }
  .bottom-navbar {
    width: 100%;
  }
  .bottom-navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  .bottom-navbar .container .navbar-items {
    display: flex;
    align-items: center;
  }
  .navbar-item {
    position: relative; /* Allows dropdown positioning */
    margin: 0 10px;
    list-style: none;
  }
  body.dark-theme .bottom-navbar .container {
    border-bottom: 1px solid #171C29;
  }
  body.dark-theme .bottom-navbar .container .navbar-item .navbar-link {
    color: rgba(255, 255, 255, 0.76) !important;
  }
  body.dark-theme .bottom-navbar .container .navbar-item .navbar-link:hover {
    color: #ffff
  }

  /* Dropdown menu */
  .dropdown-menu {
    position: absolute;
    top: 100%; /* Positions dropdown below the link */
    left: 0;
    margin-top: 3px;
    display: none;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    list-style: none;
    z-index: 1000;
    width: 275px;
  }
  body.dark-theme .dropdown-menu {
    background-color: #535A70;
    color: rgb(255 255 255 / 76%);
  }
  body.dark-theme .dropdown-menu li a {
    color: rgb(255 255 255 / 76%);
  }

  .main-navbar ul li a::before {
    content: "";
    position: absolute;
    width: 100%;
    background-color: #4465BA;
    height: 1px;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
}
  .navbar-link {
    /*font-family: 'Inter';*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #6B7280 !important;
    text-decoration: none;
    /*padding: 8px 5px 19px 5px;*/
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #fff; */
    position: relative;
    overflow: hidden;
  }
  .navbar-link img {
    margin: 0 10px;
  }
  .dropdown-menu li {
    padding: 10px 16px;
    border-bottom: 1px solid #ccc;
  }
  
  .dropdown-menu li a {
    color: #333;
    text-decoration: none;
    display: block;
  }
   .navbar-item:hover .navbar-link  {
    color: #800;
   }
  /* Show dropdown on hover */
  .navbar-item:hover .dropdown-menu {
    display: block;
    animation: fadeInDropdown 0.3s ease-in-out;
    
  }
  
  /* Dropdown animation */
  @keyframes fadeInDropdown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    .navbar-btns {
      display: none; /* Hide the navbar buttons on mobile */
    }
  
    .search-container {
      display: none; /* Hide search input on mobile */
    }
  
    .mobile-menu-btn {
      display: block; /* Show mobile menu button */
    }
  
    .bottom-navbar {
      display: none; /* Hide bottom navbar by default */
      position: absolute;
      width: 100%;
      top: 60px; /* Adjust as per logo height */
      background-color: #fff;
    }
  
    .bottom-navbar.open {
      display: block; /* Show when menu is open */
    }
  
    .navbar-items {
      list-style-type: none;
      padding: 0;
    }
  
    .navbar-item {
      margin: 10px 0;
    }
  
    .navbar-link {
      text-decoration: none;
      color: #333;
      font-weight: bold;
    }
  
    .dropdown-menu {
      list-style-type: none;
      padding-left: 20px;
    }
  
    .dropdown-menu li {
      margin: 5px 0;
    }
  }