.news-item{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.news-item img {
    margin-right: 24px;
    width: 427px;
    height: 279px;
    border-radius: 6px;
}

.news-container {
    width: 100%;

}

.news-container .container {
 padding: 0;
}

.news-page-box {
    display: flex;
    margin-bottom: 64px;
    margin-top: 5rem;
}

.news-page-box > div h1 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #111827;
    margin-bottom: 16px;
}

body.dark-theme .news-page-box > div h1 {
    color: #939FB8 !important;
}
body.dark-theme  .news-page-box > div p {
    color: #939FB8 !important;
}

body.dark-theme .link_all {
    color: #939FB8 !important;
} 
.news-page-box > div p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #111827;
    margin-bottom: 40px;
    margin-top: 16px;
}

.link_all {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'salt' on;
    color: #264DB1;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 3rem;
}

.pagination button {
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination .active {
    /*background-color: #0056b3;*/
    color: #0056b3;
    font-weight: bold;
}
