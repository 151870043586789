:root {
    --Bgcolor: #fff;
    --Blackcolor: #264DB1;
    --Whitecolor: #fff;
    --Graycolor: #ccc;
    --Darkgraycolor: #666;
    --Lightgraycolor: #f5f5f5;
    --Redcolor: #f00;
    --Greencolor: #0f0;
    --Bluecolor: #00f;
    --Yellowcolor: rgb(255, 187, 0);
    --Purplecolor: #800;
    --Orangecolor: #ff8000;
    --Pinkcolor: #ff0;
    --Font-family: "Roboto", sans-serif;
}


/* Header.css */
.header {
    width: 100%;
    background-color: var(--Blackcolor);
    color: white;
    padding: 8px 10px;
    
  }
  .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
  .header .left-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .header .right-header {
    display: flex;
    align-items: center;
  }
  .header .right-header .emblems span {
    font-size: 13px;
  }
  .header .left-header .mail {
    margin-left: 25px;
    font-size: 13px;
    text-decoration: none;
    color: var(--Whitecolor);
    font-weight: 450;
  }
  .header .left-header .mail span:last-child {
    margin-left: 5px;
  }
  .header .socials .social-link{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: #4465BA;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
    margin: 0 2px;
  }

  .header .socials .social-link .social-icon {
    color: var(--Whitecolor);
    font-size: 14px;
  }
  .emblems, .schema-site {
    border-right: 1px solid #fff;
    padding: 0 10px;
    text-align: center;
  }
  .theme-toggle {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .theme-label {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .theme-icon {
    color: white;
    font-size: 16px;
    transition: opacity 0.3s ease;
    margin-right:5px;
  }
  
  .theme-icon.hidden {
    opacity: 0;
  }
  
  .language-dropdown {
    position: relative;
    align-items: center;
    display: flex;
  }
  
  .lang-btn {
    display: flex;
    align-items: flex-end;
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  
  .current-lang-text {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 14px;
  }
  
  .lang-flag {
    width: 20px;
    height: 15px;
    margin-right: 5px;
  }
  
  .lang-options {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    color: black;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 150px;
  }
  
  .lang-option {
    display: flex;
    align-items: center;
    padding: 5px;
    border: none;
    background: none;
    width: 100%;
    cursor: pointer;
  }
  
  .lang-option img {
    width: 24px;
    height: 18px;
    margin-right: 10px;
  }
  
  .lang-option:hover {
    background-color: #f0f0f0;
  }
  
  .emblems, .schema-site {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--Font-family);
  }
  
  .emblems:hover, .schema-site:hover {
    text-decoration: underline;
  }
  
  /* Тема для темного режима */
  body.dark-theme {
    background-color: #252C3F;
    color: white;
  }
  
  body.dark-theme .header {
    background-color: #171C29;
  }
  body.dark-theme .header .socials .social-link {
    background-color: #252C3F;
  }

  @media (max-width: 768px) {
    .header .left-header .mail  {
      display: none;
    }

    .header .container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .emblems, .schema-site {
    display: none;
  }

  .theme-icon, .lang-icon {
    display: none;
  }
  .navbar-btns.slide-in {
    display: none;
  }
  .navbar .center-navbar .container {
    width: 100%;
  }
  .home-container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .news-section {
    width: 100%;
    margin-bottom: 5rem;
    flex-direction: column;
}
.news-large {
  width: 30%;
  height: 480px;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
  }