
.admin-container {
    display: flex;
    min-height: 100vh;
}

/* Sidebar */
.sidebar {
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 9999;
}

.sidebar-header {
    padding: 20px;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    background-color: #444;
}

.sidebar-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-menu li {
    padding: 15px 20px;
    border-bottom: 1px solid #444;
}

.sidebar-menu a {
    color: white;
    text-decoration: none;
    display: block;
}

.sidebar-menu li:hover {
    background-color: #555;
}

/* Main Content */
.main-content {
    margin-left: 250px;
    padding: 20px;
    background-color: #fff;
    width: calc(100% - 250px);
}

.content {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.list-item {
    margin-bottom: 10px;
}

.upload-form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
}

.btn-upload {
    background-color: #28a745;
    color: white;
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.btn-delete {
    background: none;
    color: #333;
    text-decoration: none;
    margin-right: 10px;
}

.btn-edit {
    background: none;
    color: #333;
    text-decoration: none;  
}
.photo {
    width: 50px;
    height: auto;
    border-radius: 4px;
}
